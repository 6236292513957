import apiLive from '@services/api/utils/live'

export default {
  async beforeCreate(context) {
    const { $http } = context
    await apiLive
      .live($http)
      .promise.then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  },
}
