import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/account', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/account', params, payload, config)
  },

  init(axios, params, query, config) {
    return api.get(axios, '/account/login', params, query, config)
  },

  login(axios, params, payload, config) {
    return api.post(axios, '/account/login', params, payload, config)
  },

  logout(axios, params, config) {
    return api.delete(axios, '/account/logout', params, config)
  },

  panel(axios, params, config) {
    return api.get(axios, '/account/panel', params, config)
  },

  uniqueUsername(axios, params, query, config) {
    return api.get(axios, '/account/unique_username', params, query, config)
  },

  userinfo(axios, params, config) {
    return api.get(axios, '/account/userinfo', params, config)
  },
}
