import Account from '@common/security/account'
import apiAccount from '@services/api/account'

export default () => {
  return {
    namespaced: true,
    state: () => ({
      account: null,
    }),
    getters: {
      currentAccount: (state) => state.account || {},
      isAuthenticated: (state) => !!state.account,
      isAdmin: (state) => state.account && state.account.rol.includes('admin'),
      isManager: (state) => state.account && state.account.rol.includes('manager'),
      isAM: (state) =>
        state.account && state.account.rol.some((v) => ['admin', 'manager'].includes(v)),
      isTechnician: (state) => state.account && state.account.rol.includes('technician'),
      isSubscriber: (state) => state.account && state.account.rol.includes('subscriber'),
    },
    mutations: {
      login(state, data) {
        state.account = Account.from(data)
        this.$dayjs.tz.setDefault(state.account.tiz)
      },
      logout(state) {
        state.account = null
      },
      getCurrentAccount(state, data) {
        state.account = Account.from(data)
      },
    },
    actions: {
      login({ commit }, data) {
        commit('login', data)
      },
      logout({ commit }) {
        commit('logout')
      },
      getCurrentAccount({ commit }) {
        return apiAccount
          .userinfo(this.$http)
          .promise.then((response) => {
            commit('getCurrentAccount', response.data)
            return response
          })
          .catch((error) => {
            return error
          })
      },
    },
  }
}
