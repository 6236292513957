import { asyncLoadRouter } from '@router/helper'

const account = [
  {
    path: 'account',
    component: asyncLoadRouter('manager/Account'),
    children: [
      {
        path: '',
        name: 'manager-account-show',
        component: asyncLoadRouter('manager/account/Show'),
        redirect: () => {
          return {
            name: 'manager-account-edit',
          }
        },
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('account.menus.my_account'), to: { name: 'manager-account-edit' } },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-account-edit',
            component: asyncLoadRouter('manager/account/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default account
