import axios from 'axios'
import cookies from 'axios/lib/helpers/cookies'

export default {
  beforeCreate(context, inject, vueOptions) {
    const { router, store, req } = context
    const { wc } = vueOptions

    /* Create axios instance. */
    const instance = axios.create({
      baseURL: req ? process.env.VUE_APP_WC_AUTH_API_IURL : process.env.VUE_APP_WC_AUTH_API_URL,
      headers: {
        'wc-Client': process.env.VUE_APP_WC_AUTH_AUD,
        Accept: 'application/vnd.api+json, application/vnd.windegger.v1+json',
        'Content-Type': 'application/vnd.api+json',
      },
      withCredentials: true,
      xsrfCookieName: undefined,
      xsrfHeaderName: undefined,
    })

    /* Add cancelToken property. */
    instance.CancelToken = axios.CancelToken

    /* Request interceptor. */
    instance.interceptors.request.use((config) => {
      /* Set origin header on server side requests. */
      if (req) config.headers.origin = process.env.VUE_APP_WC_AUTH_WEB_URL

      /* Set cookie header on server side request. */
      if (req && req.headers && req.headers.cookie) config.headers.cookie = req.headers.cookie

      /* Set accepted locale. */
      config.headers['Accept-Language'] = wc.shared.locale || store.getters['ui/getLocale']

      /* Don't pass sensible header data if URL is external. */
      if (config.url.startsWith('/') || config.url.startsWith(config.baseURL)) {
        /* Set right CSRF token from cookie. */
        const csrf = req
          ? req.cookies[`_${wc.conf.base.prefix}${wc.conf.env.short}_csrf`]
          : cookies.read(`_${wc.conf.base.prefix}${wc.conf.env.short}_csrf`)
        if (csrf) config.headers['X-CSRF-Token'] = csrf
      }

      return config
    })

    /* Response interceptor. */
    instance.interceptors.response.use(
      (response) => {
        if (
          !response.config.isBackground &&
          response.data &&
          response.data.meta &&
          response.data.meta.redirect
        ) {
          response.data.meta.redirect.remote
            ? window.location.replace(response.data.meta.redirect.remote)
            : router.push(response.data.meta.redirect)
        }
        return Promise.resolve(response)
      },
      (error) => {
        /* The request was made and the server responded with a status code. */
        if (!error.config || !error.config.isBackground) {
          if (error.response) {
            if (error.response.status === 401) {
              store.dispatch('auth/logout')
              router.push({
                name: 'login',
              })
            } else if (
              error.response.data &&
              error.response.data.meta &&
              error.response.data.meta.redirect
            ) {
              router.push(error.response.data.meta.redirect)
            }
            /* The request was made but no response was received. */
          } else if (error.request) {
            error.request.data = {
              meta: {
                notices: [
                  {
                    id: new Date() / 100000,
                    type: 'danger',
                    title: router.app
                      ? router.app.$i18n.t('notices.network.no_response.error.title')
                      : 'Error',
                    detail: router.app
                      ? router.app.$i18n.t('notices.network.no_response.error.detail')
                      : 'The request was made but no response was received.',
                  },
                ],
              },
            }
            /* Something happened in setting up the request. */
          } else {
            error.data = {
              meta: {
                notices: [
                  {
                    id: new Date() / 100000,
                    type: 'danger',
                    title: router.app
                      ? router.app.$i18n.t('notices.network.no_request.error.title')
                      : 'Error',
                    detail: router.app
                      ? router.app.$i18n.t('notices.network.no_request.error.detail')
                      : 'Something happened performing the request.',
                  },
                ],
              },
            }
          }
        }
        return Promise.reject(error)
      }
    )

    inject('http', instance)
  },
}
