import dashboard from '@store/modules/manager/dashboard'
import accounts from '@store/modules/manager/accounts'

/**
 * @param {object} wc A configuration object.
 * @returns {string} A window width.
 */
function defineWindowWidth(wc) {
  return (process.client || process.spa ? window.innerWidth : wc.conf.base.mobile_breakpoint - 1) <
    wc.conf.base.mobile_breakpoint
    ? false
    : true
}

export default (wc) => {
  return {
    namespaced: true,
    modules: {
      dashboard,
      accounts,
    },
    state: () => ({
      aside: defineWindowWidth(wc),
    }),
    getters: {
      isAsideOpen: (state) => state.aside,
    },
    mutations: {
      windowChanged(state) {
        state.aside = defineWindowWidth(wc)
      },
      toggleAside(state) {
        state.aside = !state.aside
      },
      openAside(state) {
        state.aside = true
      },
      closeAside(state) {
        state.aside = false
      },
    },
    actions: {
      windowChanged({ commit }) {
        commit('windowChanged')
      },
      toggleAside({ commit }) {
        commit('toggleAside')
      },
      openAside({ commit }) {
        commit('openAside')
      },
      closeAside({ commit }) {
        commit('closeAside')
      },
    },
  }
}
