import Vue from 'vue'
import Vuex from 'vuex'
import cache from 'vuex-cache'
import ui from '@store/modules/ui'
import auth from '@store/modules/auth'
import base from '@store/modules/base'
import account from '@store/modules/account'
import manager from '@store/modules/manager'

Vue.use(Vuex)

export default (wc) => {
  const store = new Vuex.Store({
    plugins: [cache({ timeout: wc.conf.cache.default || 900000 })],
    /* IMPORTANT: state must be a function so the module can be
     * instantiated multiple times
     */
    state: () => ({}),
    mutations: {},
    actions: {},
    modules: {
      ui: ui(wc),
      auth: auth(wc),
      base: base(wc),
      manager: manager(wc),
      account,
    },
  })

  return store
}
