import api from '@services/api'

export default {
  panelAccounts(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts', params, query, config)
  },

  panelAccountsActive(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts_active', params, query, config)
  },

  panelAccountsConfirmed(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts_confirmed', params, query, config)
  },

  panelAccountsLocked(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts_locked', params, query, config)
  },

  panelAccountsAccepted(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts_accepted', params, query, config)
  },
}
