import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-primary m-2',
    cancelButton: 'btn btn-secondary m-2',
  },
})
