import { asyncLoadRouter } from '@router/helper'

const accounts = [
  {
    path: 'accounts',
    component: asyncLoadRouter('manager/Accounts'),
    meta: {
      authorize: ['admin', 'manager'],
      breadcrumb: (i18n) => [
        { text: i18n.t('activerecord.models.account.other'), to: { name: 'manager-accounts' } },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-accounts',
        component: asyncLoadRouter('manager/accounts/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: 'new',
        name: 'manager-accounts-new',
        component: asyncLoadRouter('manager/accounts/New'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.actions.new'), to: { name: 'manager-accounts-new' } },
          ],
        },
        props: { action: 'create' },
      },
      {
        path: ':account_id',
        name: 'manager-accounts-show',
        component: asyncLoadRouter('manager/accounts/Show'),
        redirect: (to) => {
          return {
            name: 'manager-accounts-edit',
            params: { account_id: to.params.account_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.account_id}`,
              to: {
                name: 'manager-accounts-edit',
                params: { account_id: params.account_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-accounts-edit',
            component: asyncLoadRouter('manager/accounts/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
          {
            path: 'roles',
            component: asyncLoadRouter('manager/accounts/Roles'),
            redirect: (to) => {
              return {
                name: 'manager-accounts-roles-edit',
                params: { account_id: to.params.account_id },
              }
            },
            meta: {
              breadcrumb: (i18n, params) => [
                {
                  text: i18n.t('activerecord.models.role.other'),
                  to: {
                    name: 'manager-accounts-roles-edit',
                    params: { account_id: params.account_id },
                  },
                },
              ],
            },
            children: [
              {
                path: 'edit',
                name: 'manager-accounts-roles-edit',
                component: asyncLoadRouter('manager/accounts/roles/Form'),
                meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
                props: { action: 'update' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default accounts
