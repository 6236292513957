import { asyncLoadRouter } from '@router/helper'

export default (wc) => {
  return [
    {
      path: 'oauth',
      component: asyncLoadRouter('Oauth'),
      redirect: () => {
        return { name: 'oauth-logout' }
      },
      children: [
        {
          path: 'authorize',
          name: 'oauth-authorize',
          component: asyncLoadRouter('oauth/Authorize'),
        },
        {
          path: 'logout',
          name: 'oauth-logout',
          redirect: (to) => {
            return {
              name: 'logout',
              query: {
                ...to.query,
                locale: to.query.ui_locales || wc.shared.locale || wc.conf.base.default_locale,
              },
            }
          },
        },
      ],
    },
  ]
}
