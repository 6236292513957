import apiBase from '@services/api/manager/base'

export default {
  namespaced: true,
  actions: {
    getPanelAccounts(_, payload) {
      return apiBase.panelAccounts(this.$http, payload)
    },
    getPanelAccountsActive(_, payload) {
      return apiBase.panelAccountsActive(this.$http, payload)
    },
    getPanelAccountsConfirmed(_, payload) {
      return apiBase.panelAccountsConfirmed(this.$http, payload)
    },
    getPanelAccountsLocked(_, payload) {
      return apiBase.panelAccountsLocked(this.$http, payload)
    },
    getPanelAccountsAccepted(_, payload) {
      return apiBase.panelAccountsAccepted(this.$http, payload)
    },
  },
}
