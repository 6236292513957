import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/accounts', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/accounts/:id', params, query, config)
  },

  create(axios, params, payload, config) {
    return api.post(axios, '/manager/accounts', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/accounts/:id', params, payload, config)
  },

  panel(axios, params, query, config) {
    return api.get(axios, '/manager/accounts/:id/panel', params, query, config)
  },

  uniqueUsername(axios, params, query, config) {
    return api.get(axios, '/manager/accounts/unique_username', params, query, config)
  },
}
