import { asyncLoadRouter } from '@router/helper'
import account from '@router/paths/manager/account'
import accounts from '@router/paths/manager/accounts'

const manager = [
  {
    path: 'manager',
    component: asyncLoadRouter('Manager'),
    meta: { requiresAuth: true, authorize: ['admin', 'manager'] },
    children: [
      {
        path: '',
        name: 'manager',
        component: asyncLoadRouter('manager/Dashboard'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.menus.dashboard'), to: { name: 'manager' } },
            { text: i18n.t('shared.actions.show') },
          ],
        },
      },
      ...account,
      ...accounts,
    ],
  },
]

export default manager
