/* eslint no-console: ["error", { allow: ["error"] }] */

import { placeholders } from '@common/placeholders'

export default {
  get(axios, resource, params = {}, query = {}, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios
      .get(placeholders(resource, params), {
        ...config,
        cancelToken: source.token,
        params: query,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(
          `[WC] Api GET error! ${error}.`,
          `Request: ${axios.defaults.baseURL}${placeholders(resource, params)}`
        )
        throw config.responseErrorRaw
          ? error
          : error.response
          ? error.response.data
          : error.request
          ? error.request.data
          : error.data
      })
    return { promise, source }
  },

  post(axios, resource, params = {}, payload, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios
      .post(placeholders(resource, params), payload, {
        ...config,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(
          `[WC] Api POST error! ${error}.`,
          `Request: ${axios.defaults.baseURL}${placeholders(resource, params)}`
        )
        throw config.responseErrorRaw
          ? error
          : error.response
          ? error.response.data
          : error.request
          ? error.request.data
          : error.data
      })
    return { promise, source }
  },

  update(axios, resource, params = {}, payload, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios
      .put(placeholders(resource, params), payload, {
        ...config,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(
          `[WC] Api PUT error! ${error}.`,
          `Request: ${axios.defaults.baseURL}${placeholders(resource, params)}`
        )
        throw config.responseErrorRaw
          ? error
          : error.response
          ? error.response.data
          : error.request
          ? error.request.data
          : error.data
      })
    return { promise, source }
  },

  delete(axios, resource, params = {}, config = {}) {
    const source = axios.CancelToken.source()
    const promise = axios
      .delete(placeholders(resource, params), {
        ...config,
        cancelToken: source.token,
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(
          `[WC] Api DELETE error! ${error}.`,
          `Request: ${axios.defaults.baseURL}${placeholders(resource, params)}`
        )
        throw config.responseErrorRaw
          ? error
          : error.response
          ? error.response.data
          : error.request
          ? error.request.data
          : error.data
      })
    return { promise, source }
  },
}
