import { asyncLoadRouter } from '@router/helper'

const account = [
  {
    path: 'account',
    component: asyncLoadRouter('Account'),
    redirect: () => {
      return { name: 'account-password-new' }
    },
    children: [
      {
        path: 'password',
        component: asyncLoadRouter('account/Password'),
        redirect: () => {
          return { name: 'account-password-new' }
        },
        children: [
          {
            path: 'new',
            name: 'account-password-new',
            component: asyncLoadRouter('account/password/New'),
            props: { action: 'create' },
          },
          {
            path: 'edit',
            name: 'account-password-edit',
            component: asyncLoadRouter('account/password/Edit'),
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'confirmation',
        component: asyncLoadRouter('account/Confirmation'),
        children: [
          {
            path: '',
            name: 'account-confirmation-show',
            component: asyncLoadRouter('account/confirmation/Show'),
            props: { action: 'get' },
          },
          {
            path: 'new',
            name: 'account-confirmation-new',
            component: asyncLoadRouter('account/confirmation/New'),
            props: { action: 'create' },
          },
        ],
      },
      {
        path: 'unlock',
        component: asyncLoadRouter('account/Unlock'),
        children: [
          {
            path: '',
            name: 'account-unlock-show',
            component: asyncLoadRouter('account/unlock/Show'),
            props: { action: 'get' },
          },
          {
            path: 'new',
            name: 'account-unlock-new',
            component: asyncLoadRouter('account/unlock/New'),
            props: { action: 'create' },
          },
        ],
      },
    ],
  },
]

export default account
