import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default (wc) => {
  const messages = {}
  messages[wc.conf.base.default_locale] = {
    notices: {
      network: {
        no_request: {
          error: {
            title: 'Error',
            detail: 'Something happened performing the request!',
          },
        },
        no_response: {
          error: {
            title: 'Error',
            detail: 'The request was made but no response was received!',
          },
        },
      },
    },
  }

  return new VueI18n({
    silentTranslationWarn: true,
    fallbackLocale: wc.conf.base.default_locale,
    messages: messages,
  })
}
