import { asyncLoadRouter } from '@router/helper'
import account from '@router/paths/account'
import oauth from '@router/paths/oauth'
import manager from '@router/paths/manager'

export default (wc) => {
  return [
    {
      path: '/',
      component: {
        render(h) {
          return h('router-view')
        },
      },
      children: [
        {
          path: '',
          name: 'home',
          component: asyncLoadRouter('Home'),
          meta: { requiresAuth: true },
        },
        {
          path: 'login',
          name: 'login',
          component: asyncLoadRouter('Login'),
          props: { action: 'login' },
        },
        {
          path: 'logout',
          name: 'logout',
          component: asyncLoadRouter('Logout'),
        },
        {
          path: 'register',
          name: 'register',
          component: asyncLoadRouter('Register'),
          props: { action: 'register' },
        },
        ...account,
        ...oauth(wc),
        ...manager,
      ],
    },
    {
      path: '*',
      redirect: () => {
        return {
          path: '/',
        }
      },
    },
  ]
}
