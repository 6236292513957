import Vue from 'vue'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'

/**
 * @param {object} wc A config instance.
 * @param {object} router A router instance.
 */
export default (wc, router) => {
  if (!process.env.VUE_APP_WC_AUTH_APM_URL) return

  const context = process.client || process.spa ? 'client' : 'server'

  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: `${wc.conf.base.prefix}-frontend-${context}`,
      serverUrl: process.env.VUE_APP_WC_AUTH_APM_URL,
      serviceVersion: process.env.VUE_APP_WC_AUTH_VERSION,
      environment: process.env.VUE_APP_WC_AUTH_STAGE,
    },
  })
}
