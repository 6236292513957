/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production' && (process.client || process.spa)) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready(registration) {
      console.log('App is being served from cache by a service worker.')
      document.dispatchEvent(new CustomEvent('wc:sw:ready', { detail: registration }))
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      document.dispatchEvent(new CustomEvent('wc:sw:registered', { detail: registration }))
    },
    cached(registration) {
      console.log('Content has been cached for offline use.')
      document.dispatchEvent(new CustomEvent('wc:sw:cached', { detail: registration }))
    },
    updatefound(registration) {
      console.log('New content is downloading.')
      document.dispatchEvent(new CustomEvent('wc:sw:updatefound', { detail: registration }))
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(new CustomEvent('wc:sw:updated', { detail: registration }))
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      document.dispatchEvent(new CustomEvent('wc:sw:offline'))
    },
    error(error) {
      console.error('Error during service worker registration:', error)
      document.dispatchEvent(new CustomEvent('wc:sw:error', { detail: error }))
    },
  })
}
