<template lang="pug">
  #app
    router-view
</template>

<script>
export default {
  name: 'container',
  computed: {
    metaLinksAlternate() {
      return this.$wc.conf.base.available_locales.map((k) => ({
        rel: 'alternate',
        href: `${process.env.VUE_APP_WC_AUTH_WEB_URL}${
          this.$router.resolve({ query: { ...this.$route.query, l: k } }).href
        }`,
        hreflang: k,
      }))
    },
  },
  methods: {
    handleResize() {
      clearTimeout(this.timer.resize)
      this.timer.resize = setTimeout(() => {
        this.$store.dispatch('ui/windowChanged')
        this.$store.dispatch('manager/windowChanged')
      }, 500)
    },
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  data() {
    return {
      timer: {},
    }
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: this.$store.getters['ui/getLocale'],
      },
      bodyAttrs: {
        'data-wc': 'frontend-auth',
      },
      title: this.$t('meta.name'),
      titleTemplate: `%s › ${this.$t('meta.slogan')}`,
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
        },
        { name: 'HandheldFriendly', content: 'true' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
        { name: 'apple-mobile-web-app-title', content: this.$t('meta.name') },
        { name: 'application-name', content: this.$t('meta.name') },
        { name: 'msapplication-TileColor', content: '#ffffff' },
        { name: 'msapplication-TileImage', content: '/images/icons/mstile-144x144.png' },
        { name: 'msapplication-config', content: '/browserconfig.xml' },
        { name: 'theme-color', content: '#aa1675' },
        { name: 'autor', content: this.$t('meta.author') },
        { name: 'copyright', content: this.$t('meta.author') },
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          sizes: '16x16 32x32 48x48',
          href: '/images/icons/favicon.ico',
        },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/images/icons/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/images/icons/favicon-16x16.png' },
        {
          rel: 'shortcut icon',
          sizes: '192x192',
          href: '/images/icons/android-chrome-192x192.png',
        },
        {
          rel: 'shortcut icon',
          sizes: '512x512',
          href: '/images/icons/android-chrome-512x512.png',
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: '/images/icons/apple-touch-icon-180x180.png',
        },
        { rel: 'mask-icon', color: '#aa1675', href: '/images/icons/safari-pinned-tab.svg' },
        { rel: 'manifest', href: '/manifest.json' },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500&display=swap',
        },
        {
          rel: 'canonical',
          href: `${process.env.VUE_APP_WC_AUTH_WEB_URL}${this.$route.fullPath}`,
        },
        ...this.metaLinksAlternate,
      ],
    }
  },
}
</script>
