import apiAccounts from '@services/api/manager/accounts'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiAccounts.panel(this.$http, payload)
    },
  },
}
