/**
 * @param {string} resource An API path as string.
 * @param {object} params An object with parameters.
 * @returns {string} A resource path with replaced placeholders.
 */
export function placeholders(resource, params = {}) {
  return resource.replace(/:([\w,.]+)/g, (all) => {
    return params[all.substring(1)] || all
  })
}
