import Vue from 'vue'
import VueNoty from '@common/notifications/noty'

Vue.use(VueNoty, {
  callbacks: {
    onTemplate() {
      if (this.options.template === 'default') {
        this.barDom.innerHTML =
          '<div class="noty_body noty_template_default">' +
          this.options.text +
          '</div><div class="noty_progressbar"></div>'
      } else if (this.options.template === 'simple') {
        this.barDom.innerHTML = `
        <div class="noty_body noty_template_simple">
          <div class="noty_icon"><i class="${this.options.icon}"></i></div>
          <div class="noty_msg">
            <div class="noty_title">${this.options.title}</div>
            <div class="noty_text">${this.options.text}</div>
          </div>
        </div>
        <div class="noty_progressbar"></div>`
      }
    },
  },
})
